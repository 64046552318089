import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '30, 39, 46',
		'primary-dark': '26, 31, 35',
		'accent': '11, 232, 129',
		'accent-plus': '26, 31, 35',
	},
});
